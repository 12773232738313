import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KullaniciTur } from '../../customdirective/Login.service';
import { SabitDegerlerService } from '../../customdirective/sabitdegerler.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  secilidil;
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  temamenuleri = false;
  oturumacanpersonel;
  oturumacankullanicituru; oturumacankullaniciadsoyad;
  constructor(private sbt: SabitDegerlerService, private jwtHelper: JwtHelperService, private router: Router) {
    this.secilidil = this.sbt.secilidil;
    this.oturumacanpersonel = JSON.parse(localStorage.getItem('oturumacankullanici'));
    if (this.oturumacanpersonel != null) this.oturumacankullanicituru = this.oturumacanpersonel.kullanicituru;
    if (this.oturumacanpersonel != null) this.oturumacankullaniciadsoyad = this.oturumacanpersonel.AdSoyad;
  }
  kalanzaman;
  ngOnInit() {
   
    this.oturumacanpersonel = JSON.parse(localStorage.getItem('oturumacankullanici'));
    if (this.oturumacanpersonel != null) this.oturumacankullaniciadsoyad = this.oturumacanpersonel.AdSoyad;
    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  jwtaktifmi() {
    const token = localStorage.getItem("jwt");
    if (token != null)
      this.kalanzaman = this.sbt.ikitariharasindakizamanial(this.jwtHelper.getTokenExpirationDate(token), new Date())
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    else {
      const oturumacanpersonel = JSON.parse(localStorage.getItem("oturumacankullanici"));
      if (oturumacanpersonel != null) {
        if (<any>oturumacanpersonel.kullanicituru == KullaniciTur.Firma_Sahibi)
          this.router.navigate(['/FirmaYonetimSayfalar/GirisYap']);
        else if (<any>oturumacanpersonel.kullanicituru == KullaniciTur.Yonetici)
          this.router.navigate(['/FirmaYonetimSayfalar/GirisYap']);
        else if (<any>oturumacanpersonel.kullanicituru == KullaniciTur.Sorumlu)
          this.router.navigate(['/FirmaPersonelSayfalar/GirisYap']);
        
      }
      return true;
    }
  }

}
