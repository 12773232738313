import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LoginService {
  u;
  constructor(private router: Router) {
    this.u = new Kullanici();
    if (this.u.KullaniciID == undefined) {
      let logvarmi = JSON.parse(localStorage.getItem("oturumacankullanici"));
     
        if (logvarmi != null && logvarmi.KullaniciID != null)
          this.logdankullaniciokundu(logvarmi);
        else {
          if (location.pathname.substring(1, 12) == "FirmaYoneti")
            this.router.navigate(['/FirmaYonetimSayfalar/GirisYap']);
         
          else
            this.router.navigate(['/']);
        }
      
    }
  }
  yoneticiloginoldu(yoneticibilgileri) {
    this.u = new Kullanici();
    this.u.KullaniciID = yoneticibilgileri.userID;

    this.u.AdSoyad = yoneticibilgileri.AdSoyad;
    this.u.Mail = yoneticibilgileri.mail;
    //this.u.Sifre = yoneticibilgileri.sifre;
    this.u.baglidepoId = yoneticibilgileri.baglidepoId;
    this.u.oturumactimi = true;
    this.u.kullanicituru = KullaniciTur.Firma_Sahibi;
    localStorage.setItem('oturumacankullanici', JSON.stringify(this.u));
  }

  firmayetkililoginoldu(yetkilibilgileri) {
    this.u = new Kullanici();
    this.u.KullaniciID = yetkilibilgileri.userID;
    this.u.FirmaYetkiliID = yetkilibilgileri.userID;
    this.u.AdSoyad = yetkilibilgileri.AdSoyad;
    this.u.Mail = yetkilibilgileri.mail;
    //this.u.Sifre = yoneticibilgileri.sifre;
    this.u.baglidepoId = yetkilibilgileri.baglidepoId;
    this.u.oturumactimi = true;
    this.u.kullanicituru = KullaniciTur.Yonetici;
    localStorage.setItem('oturumacankullanici', JSON.stringify(this.u));
  }

 
  firmasorumluloginoldu(personelbilgileri) {
    this.u = new Kullanici();
    this.u.KullaniciID = personelbilgileri.userID;

    this.u.AdSoyad = personelbilgileri.AdSoyad;
    this.u.Mail = personelbilgileri.mail;
    this.u.baglidepoId = personelbilgileri.baglidepoId;
    this.u.oturumactimi = true;
    this.u.kullanicituru = KullaniciTur.Sorumlu;
    localStorage.setItem('oturumacankullanici', JSON.stringify(this.u));
  }
  logdankullaniciokundu(kullanicibilgileri) {
    this.u = new Kullanici();
    this.u.KullaniciID = kullanicibilgileri.userID;
    this.u.AdSoyad = kullanicibilgileri.AdSoyad;
    this.u.Mail = kullanicibilgileri.mail;
    this.u.kullanicituru = kullanicibilgileri.kullanicituru;
    this.u.baglidepoId = kullanicibilgileri.baglidepoId;
    

  }
}

export class Kullanici {
  KullaniciID: number;
  AdSoyad: string;
  Mail: string;
  Sifre: string;
  oturumactimi: boolean;
  kullanicituru: number;
  baglidepoId: number;

}
export enum KullaniciTur {
  Firma_Sahibi = 1,
  Yonetici = 2,
  Sorumlu = 3,
  
}
