import { Component, Directive, ElementRef, Input } from '@angular/core';

@Component({
  selector: '[customtable]',
  templateUrl: 'customdirectivesayfa.html'
})
export class CustomtableDirective {

  constructor(private el: ElementRef) {
    alert("deneme");
    el.nativeElement.class = "table table-responsive table-hover";
  }


}
