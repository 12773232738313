import { Component, NgModule, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../customdirective/Login.service'
import { SabitDegerlerService } from '../../customdirective/sabitdegerler.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})

export class NavbarComponent implements OnInit {


  secilidil;
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  TemaMenuleriGorunsunMu = false;
  constructor(config: NgbDropdownConfig, private login: LoginService, private sbt: SabitDegerlerService) {
    config.placement = 'bottom-right';
    this.secilidil = this.sbt.secilidil;
  }

  oturumacankullanici = null;
  oturumacankullaniciadsoyad;
  ngOnInit() {
    // Oturum açan kullanıcı doldur
    this.oturumacankullanici = JSON.parse(localStorage.getItem('oturumacankullanici'));
    if (this.oturumacankullanici != null) this.oturumacankullaniciadsoyad = this.oturumacankullanici.AdSoyad;
  }
  dildegisti(dil) {
    this.sbt.dildegisti(dil);
    location.reload();
  }
  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  oturumukapat() {
    localStorage.setItem("oturumacankullanici", null);
    localStorage.removeItem("jwt");
    location.reload();
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }
  static toggleSidebar(veri) {
    let body = document.querySelector('body');
    let iconOnlyToggled = false;
    let sidebarToggled = true;
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      iconOnlyToggled = veri;
      if (iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      sidebarToggled = veri;
      if (sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }



  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

}
