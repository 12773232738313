import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private jwtHelper: JwtHelperService) {
  }

  canActivate() {
    const token = localStorage.getItem("jwt");
    const oturumacankullanici = JSON.parse(localStorage.getItem("oturumacankullanici"));
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else
      return false;
  }
}
