import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
const H = window['H'];
@Injectable({
  providedIn: 'root'
})
export class SabitDegerlerService {
  apiurl;
  secilidil;
  apikey;
  girisyapildimi = false;
  constructor(private router: Router) {
    if (window.location.hostname == "localhost")
      this.apiurl = "https://localhost:7202/api/";
    else
      this.apiurl = "https://api.akrefrakter.com/api/";
    this.secilidil = localStorage.getItem("dil");
    if (this.secilidil == null) {
      this.dildegisti('TR');

    }
    

  }
  dildegisti(dil) {
    localStorage.setItem('dil', dil);
    this.secilidil = dil;
  }

  anasayfayadon() {
    this.router.navigate(['/']);
  }

  dizidenelemansil(array, element) {
    array.forEach((value, index) => {
      if (value == element) array.splice(index, 1);
    });
  }
  ikitariharasindakizamanial(buyuktarih, kucuktarih) {

    var seconds = Math.floor((buyuktarih - kucuktarih) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " yıl";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " ay";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " gün";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " saat";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " dakika";
    }
    return Math.floor(seconds) + " saniye";
  }
  currentPage = 1;
  itemsPerPage = 20;
  pageSize: number;
  public onPageChange(pageNum: number): void {

    this.pageSize = this.itemsPerPage * (pageNum - 1);

  }
  public changePagesize(num: number): void {

    this.itemsPerPage = this.pageSize + num;

  }


  
}
